import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Grid, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  smallPic: {
    position: "relative",
    textAlign: "center",
    color: "white",
  },
  smallPicText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "10",
  },
}))

function ImageList() {
  const classes = useStyles()
  return (
    <StaticQuery
      query={graphql`
        query {
          aqua: file(relativePath: { eq: "teaching.jpg" }) {
            childImageSharp {
              fluid(
                traceSVG: { color: "#004357" }
              ) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
          tech: file(relativePath: { eq: "engineer.jpg" }) {
            childImageSharp {
              fluid(
                traceSVG: { color: "#004357" }
              ) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
          env: file(relativePath: { eq: "health.jpg" }) {
            childImageSharp {
              fluid(
                traceSVG: { color: "#004357" }
              ) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      `}
      render={({ aqua, tech, env }) => (
        <section>
          <Grid container>
            <Grid item xs={4} className={classes.smallPic}>
             
              <Img fluid={env.childImageSharp.fluid} style={{height: "400px"}} />
            </Grid>
            <Grid item xs={4} className={classes.smallPic}>
           
              <Img fluid={tech.childImageSharp.fluid} style={{height: "400px"}} />
            </Grid>
            <Grid item xs={4} className={classes.smallPic} >
          
              <Img fluid={aqua.childImageSharp.fluid} style={{height: "400px"}}/>
            </Grid>
          </Grid>
        </section>
      )}
    />
  )
}

export default ImageList
