import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/SEO/seo"
import {
  DividedSection,
  Title,
  Text,
  Section,
  Button,
} from "gatsby-theme-material-foundry"
import {
  Grid,
  Container,
  Divider,
  Box,
  makeStyles,
  Hidden,
  useMediaQuery
} from "@material-ui/core"
import EventCard, { NewsCard } from "../components/event-cards"
import ImageList from "../components/imagelist"
import { SendInnIde } from "../components/form-dialog"
import { useTheme } from "@material-ui/styles"
import { NewsSvg, EventSvg, StudentSvg } from "../components/svgs"
import BackgroundImage from "gatsby-background-image"
import moment from "moment"
require("moment/locale/nb")

moment.locale("nb")

const useStyles = makeStyles(theme => ({
  hashtags: {
    marginTop: "-100px",
    paddingBottom: "50px",
    color: "white",
  },
  hashtagMargins: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0 5px",
      fontSize: "12px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 50px",
      fontSize: "16px",
    },
  },
  hashtag: {
    color: "white",
    fontWeight: 400,
  },
  features: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "30px",
    },
  },
  subTitle: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "24px",
    },
  },
  hero: {
    color: "white",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
      paddingBottom: "50px",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "50px",
      paddingBottom: "50px",
    },
  },
  herotitle: {
    color: "white",
  },
  svg: {
    width: "200px",
  },
  svgContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: "50px",
  },
  newsSection: {
    background: "rgba(206,241,207,.5)",
    padding: "100px 0",
  },
  arrSection: {
    background: "#f0f9fa",
  },
  studentSection: {
    background: "white",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  financing: {
    [theme.breakpoints.down("xs")]: {
      padding: "20px 0",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "100px 20px",
    },
  },
}))

function IndexPage(props) {
  const img = props.data.cover.childImageSharp.fluid
  const fabrikkgaten = props.data.fabrikkgaten.childImageSharp.fluid
  const events = props.data.allArrangement.nodes
  const news = props.data.latestNews.nodes
  const backupEventImg = props.data.eventImg.childImageSharp.fluid
  const theme = useTheme()
  const classes = useStyles()
  return (
    <Layout>
      <SEO />
      <BackgroundImage
        alt={`cover picture`}
        fluid={img}
        className={classes.hero}
      >
        <Box align="center" pt={12} pb={6}>
          <Container maxWidth="sm" align="center">
            <Box pt={12} mb={16}>
              <Title variant="h2" align="center" className={classes.herotitle}>
                Har du en god idé?
              </Title>
              <Text variant="h5" align="center" className={classes.subTitle}>
              HVL Skape er Høgskulen på Vestlandet sitt studenttilbud innenfor innovasjon og entreprenørskap. Vi tilbyr veiledning, kurs, workshops, med mer innen temaene innovasjon og entreprenørskap
              </Text>
              <Box m={2} mb={4}>
                <SendInnIde />
              </Box>
            </Box>
          </Container>
          <Container maxWidth="lg" align="center">
            <Grid container>
              <Grid item xs={4}>
                <Text variant="h5" className={classes.hashtag} color="inherit">
                  Samspill
                </Text>
                <Text color="secondary" className={classes.hashtagMargins}>
                  Vi utvikler ny kunnskap i dialog med omverdenen og hverandre.
                </Text>
              </Grid>
              <Grid item xs={4}>
                <Text variant="h5" className={classes.hashtag} color="inherit">
                  Bærekraft
                </Text>
                <Text color="secondary" className={classes.hashtagMargins}>
                  Vi skal være en drivkraft for bærekraftig utvikling.
                </Text>
              </Grid>
              <Grid item xs={4}>
                <Text variant="h5" className={classes.hashtag} color="inherit">
                  Nyskaping
                </Text>

                <Text color="secondary" className={classes.hashtagMargins}>
                  Vi skal utruste studentene med kompetanse som gjør dem
                  kreative, løsningsorienterte og nyskapende.
                </Text>
              </Grid>
            </Grid>
          </Container>
         
        </Box>
      </BackgroundImage>

      <ImageList />

      <Box className={classes.arrSection}>
        <Container maxWidth="md">
          <DividedSection className={classes.hero}>
            <Hidden xsDown>
              <Container align="center">
                {" "}
                <EventSvg
                  fill1={theme.palette.success.main}
                  fill2={theme.palette.primary.main}
                  fill3="#e2e2ec"
                  fill4={theme.palette.black.main}
                />
              </Container>
            </Hidden>
            <Box align="center">
              <Title variant="h2" primary >
                Arrangement
              </Title>
              <Text
                black
                variant="h5"
                className={classes.subtitle}
                gutterBottom
              >
                HVL Skape arrangerer events for studenter på alle av HVL sine
                campus.
              </Text>
              <Box display="flex" justifyContent={useMediaQuery(theme.breakpoints.up('sm')) ? "flex-end" : "center"} mr={4}>
                <Button color="black" simple size="sm" to="/faste-arrangement">
                  Faste arrangement
                </Button>
                <Button
                  color="black"
                  size="sm"
                  to="/arrangement"
                  ariaLabel="se arrangement"
                >
                  Se alle arrangment
                </Button>
              </Box>
            </Box>
          </DividedSection>
        </Container>
        <Section minHeight="40vh">
          <Container>
            <Title variant="h4" black>
              Kommende arrangement
            </Title>
            <Divider />
            <Grid container>
              {events.map(e => (
                <EventCard
                  key={`arrangement-${e.id}`}
                  title={e.tittel}
                  date={
                    moment(e.slutt).diff(e.start, "days") > 0
                      ? `${moment(e.start).format("D")}-${moment(
                          e.slutt
                        ).format("D MMMM YYYY")}`
                      : moment(e.start).format("dddd, D MMMM YYYY")
                  }
                  multiDate={ moment(e.slutt).diff(e.start, "days") > 0}
                  link={`/arrangement/${e.slug}`}
                  image={e.cover}
                  backupImage={backupEventImg}
                  tags={e.tags}
                  campus={e.campus}
                  adresse={e.adresse}
                />
              ))}
            </Grid>
          </Container>
        </Section>
        <Box className={classes.newsSection}>
          <Container maxWidth="lg" align="center">
            <Grid container>
              <Grid item xs={12} md={6} className={classes.svgContainer}>
                <NewsSvg
                  fill1={theme.palette.success.main}
                  fill2={theme.palette.primary.main}
                  fill3="#e2e2ec"
                  fill4={theme.palette.black.main}
                  className={classes.svg}
                />

                <Title variant="h2" primary>
                  Nyheter
                </Title>
                <Text
                  black
                  variant="h5"
                  className={classes.subtitle}
                  gutterBottom
                >
                  Les om de siste nyhetene fra HVL Skape.
                </Text>
                <Box px={4}>
                  <Button color="black" to="/nyheter" ariaLabel="se nyheter">
                    Se flere nyheter her
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  {news.map(e => (
                    <NewsCard
                      key={`nyheter-${e.id}`}
                      title={e.tittel}
                      description={e.blurb}
                      link={`/nyheter/${e.slug}`}
                      image={e.coverUrl}
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <BackgroundImage alt={`fabrikkgaten picture`} fluid={fabrikkgaten}>
        <Box py={16}>
          <Container maxWidth="lg">
            <DividedSection className={classes.studentSection}>
              <Box className={classes.financing}>
                <Title variant="h2" primary>
                  Finansiering
                </Title>
                <Text black paragraph className={classes.subtitle} gutterBottom>
                  HVL Skape tilbyr finansiell støtte til studenter som ønsker å
                  arbeide med egne forretningsideer. I tillegg mobiliserer vi
                  til, bistår i og administrerer søknader til Forskningsrådets
                  Stud-ENT-ordning.
                </Text>
                <Button
                  color="black"
                  to="/finansiering"
                  ariaLabel="les om finansiering"
                >
                  Les mer om støtteordningene her
                </Button>
              </Box>
              <Hidden xsDown>
                <Container align="center">
                  <StudentSvg
                    fill1={theme.palette.success.main}
                    fill2={theme.palette.black.main}
                    fill3="#e2e2ec"
                    fill4={theme.palette.black.main}
                    className={classes.svg}
                  />
                </Container>
              </Hidden>
            </DividedSection>
          </Container>
        </Box>
      </BackgroundImage>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($now: Date) {
    cover: file(relativePath: { eq: "cover.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(
          maxWidth: 2000
          duotone: { highlight: "#006381", shadow: "#004357" }
          traceSVG: { color: "#004357" }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fabrikkgaten: file(relativePath: { eq: "fabrikkgaten.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(
          maxWidth: 2000
          duotone: { highlight: "#ffffff", shadow: "#e2e2e2" }
          traceSVG: { color: "#004357" }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    eventImg: file(relativePath: { eq: "event_cover.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    latestNews: allNyheter(sort: { order: DESC, fields: dato }, limit: 4) {
      nodes {
        id
        tittel
        slug
        tekst
        blurb
        coverUrl
        forfatter
        dato
      }
    }

    allArrangement(
      sort: { order: ASC, fields: start }
      limit: 3
      filter: { start: { gte: $now } }
    ) {
      nodes {
        id
        start
        slutt
        adresse
        tittel
        slug
        beskrivelse
        cover
        campus
        tags
      }
    }
  }
`
